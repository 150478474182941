import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { DropZone } from "../../common";
import uploadImageIcon from "../../../assets/images/site_icons/Duotone.svg";
import { convertToBase64 } from "../../../utils/common.service";
import { setLoader } from "../../../store/slices/masterSlice";

export default function AppSettingsChatBot({
  onSubmit,
  appSettings,
  isView,
}) {
  // console.log("🚀 ~ file: AppSettingsChatbot.js:13 ~ isView:", isView);
  const {
    handleSubmit,
    register,
    setValue,
    clearErrors,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const hours = Array.from({ length: 24 }, (_, i) => ('0' + i).slice(-2));
  const minutes = Array.from({ length: 60 }, (_, i) => ('0' + i).slice(-2));

  useEffect(() => {
    if (appSettings) {
      const {
        start_day,
        end_day,
        start_time,
        end_time
      } = appSettings;

      setValue("start_day", start_day);
      setValue("end_day", end_day);
      setValue("start_time_hour", start_time.split(":")[0]);
      setValue("start_time_minute", start_time.split(":")[1]);
      setValue("end_time_hour", end_time.split(":")[0]);
      setValue("end_time_minute", end_time.split(":")[1]);
    }
  }, [appSettings]);

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="portlet">
          <div className="horizontal_filter_card">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-4">
                <label className="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4 col-form-label text-sm-start">Start Day</label>
                <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-8">
                  <select
                    {...register("start_day", {
                      required: "Start Day Field is required.",
                    })}
                    aria-invalid={errors.start_day ? "true" : "false"}
                    className="form-select form-select-lg"
                    defaultValue=""
                    disabled={isView}
                  >
                    <option value="">Select Start Day</option>
                    <option value="monday">Monday</option>
                    <option value="tuesday">Tuesday</option>
                    <option value="wednesday">Wednesday</option>
                    <option value="thursday">Thursday</option>
                    <option value="friday">Friday</option>
                    <option value="saturday">Saturday</option>
                    <option value="sunday">Sunday</option>
                  </select>
                  {errors.start_day && (
                    <p role="alert" className="text-danger">
                      {errors.start_day?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4 col-form-label text-sm-start">End Day</label>
                <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-8">
                  <select
                    {...register("end_day", {
                      required: "End Day Field is required.",
                    })}
                    aria-invalid={errors.end_day ? "true" : "false"}
                    className="form-select form-select-lg"
                    defaultValue=""
                    disabled={isView}
                  >
                    <option value="">Select End Day</option>
                    <option value="monday">Monday</option>
                    <option value="tuesday">Tuesday</option>
                    <option value="wednesday">Wednesday</option>
                    <option value="thursday">Thursday</option>
                    <option value="friday">Friday</option>
                    <option value="saturday">Saturday</option>
                    <option value="sunday">Sunday</option>
                  </select>
                  {errors.end_day && (
                    <p role="alert" className="text-danger">
                      {errors.end_day?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="row mb-4 align-items-center">
                <label className="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4 col-form-label text-sm-start">Start Time</label>
                <div className="col-6 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                  <select
                    {...register("start_time_hour", {
                      required: "Start Time Hour Field is required.",
                    })}
                    aria-invalid={errors.start_time_hour ? "true" : "false"}
                    className="form-select form-select-lg"
                    defaultValue=""
                    disabled={isView}
                  >
                    <option value="">Select Hour</option>
                    {hours.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>
                  {errors.start_time_hour && (
                    <p role="alert" className="text-danger">
                      {errors.start_time_hour?.message}
                    </p>
                  )}
                </div>
                <div className="col-12 col-xl-1 col-lg-1 col-md-1 col-sm-1">
                  <strong>:</strong>
                </div>
                <div className="col-6 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                  <select
                    {...register("start_time_minute", {
                      required: "Start Time Minute Field is required.",
                    })}
                    aria-invalid={errors.start_time_minute ? "true" : "false"}
                    className="form-select form-select-lg"
                    defaultValue=""
                    disabled={isView}
                  >
                    <option value="">Select Minute</option>
                    {minutes.map((minute) => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))}
                  </select>
                  {errors.start_time_minute && (
                    <p role="alert" className="text-danger">
                      {errors.start_time_minute?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="row mb-4 align-items-center">
                <label className="col-12 col-xl-4 col-sm-4 col-lg-4 col-md-4 col-form-label text-sm-start">End Time</label>
                <div className="col-6 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                  <select
                    {...register("end_time_hour", {
                      required: "End Time Hour Field is required.",
                    })}
                    aria-invalid={errors.end_time_hour ? "true" : "false"}
                    className="form-select form-select-lg"
                    defaultValue=""
                    disabled={isView}
                  >
                    <option value="">Select Hour</option>
                    {hours.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>
                  {errors.end_time_hour && (
                    <p role="alert" className="text-danger">
                      {errors.end_time_hour?.message}
                    </p>
                  )}
                </div>
                <div className="col-12 col-xl-1 col-lg-1 col-md-1 col-sm-1">
                  <strong>:</strong>
                </div>
                <div className="col-6 col-xl-3 col-lg-3 col-md-3 col-sm-3">
                  <select
                    {...register("end_time_minute", {
                      required: "End Time Minute Field is required.",
                    })}
                    aria-invalid={errors.end_time_minute ? "true" : "false"}
                    className="form-select form-select-lg"
                    defaultValue=""
                    disabled={isView}
                  >
                    <option value="">Select Minute</option>
                    {minutes.map((minute) => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))}
                  </select>
                  {errors.end_time_minute && (
                    <p role="alert" className="text-danger">
                      {errors.end_time_minute?.message}
                    </p>
                  )}
                </div>
              </div>

              {!isView && (
                <div className="row justify-content-end">
                  <div className="col-12 col-md-12">
                    <div className="text-start">
                      <button className="btn btn-primary btn-lg common_hover">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div >
    </div >
  );
}
