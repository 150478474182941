import AWS from "aws-sdk";
import { AWS_STORAGE_BUCKET_NAME, AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, AWS_S3_REGION_NAME } from "../app.config";
import { getRandomString } from "../utils/common.service";

let config = {
  region: AWS_S3_REGION_NAME,
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey: AWS_SECRET_ACCESS_KEY,
};

AWS.config.update(config);

// export const uploadImageOnAWS = async (file, folder) => {
//   const s3 = new AWS.S3();
//   const fileName = `${(file.uploadtype !== undefined || file.uploadtype != null) && file.uploadtype === 'creategroup' ? getRandomString() : new Date().getTime()}${file.type?.split("/")?.[0] === "image" ? ".png" : ".mp4"}`;
//   const params = {
//     Bucket: AWS_STORAGE_BUCKET_NAME,
//     Key: `esports/${folder}/${fileName}`,
//     Body: file,
//     ACL: "public-read-write",
//   };
//   try {
//     await s3.upload(params).promise();
//     return fileName;

//   } catch (error) {
//     throw error;
//   }
// };

export const uploadImageOnAWS = async (file, folder) => {
  const s3 = new AWS.S3();
  let fileContent, fileType;

  if (typeof file === "string" && file.startsWith("data:image")) {
    const base64Data = file.replace(/^data:image\/\w+;base64,/, "");
    // fileContent = Buffer.from(base64Data, "base64");

    const binaryData = atob(base64Data); // Decode the base64 string
    const arrayBuffer = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      arrayBuffer[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the array buffer
    fileContent = new Blob([arrayBuffer], { type: "image/png" }); 
    fileType = "image/png";
  } else {
    fileContent = file;
    fileType = file.type;
  }

  const fileName = `${(file.uploadtype !== undefined || file.uploadtype != null) && file.uploadtype === 'creategroup' ? getRandomString() : new Date().getTime()}${fileType?.split("/")?.[0] === "image" ? ".png" : ".mp4"}`;
  
  const params = {
    Bucket: AWS_STORAGE_BUCKET_NAME,
    Key: `esports/${folder}/${fileName}`,
    Body: fileContent,
    // ContentType: fileType,
    ACL: "public-read-write",
  };

  try {
    await s3.upload(params).promise();
    return fileName;
  } catch (error) {
    throw error;
  }
};

