import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export default function AppSettingsPaymentGateways({ onSubmit, appSettings, isView }) {
  const publicUrl = process.env.PUBLIC_URL;
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (appSettings) {
      const {
        pay_url,
        phone_pe_merchant_id,
        phone_pe_salt_key,
        is_phone_pe_disable,
        phone_pe_salt_index,
        phone_pe_callback_url,
        phone_pe_redirect_url,
        check_status_url,
        dev_pay_url,
        dev_check_status_url,
        dev_phone_pe_merchant_id,
        dev_phone_pe_redirect_url,
        dev_phone_pe_callback_url,
        dev_phone_pe_salt_key,
        dev_phone_pe_salt_index,
        is_developer_mode,
      } = appSettings;
      setValue("is_phone_pe_disable", is_phone_pe_disable);
      setValue("pay_url", pay_url);
      setValue("phone_pe_merchant_id", phone_pe_merchant_id);
      setValue("phone_pe_salt_key", phone_pe_salt_key);
      setValue("check_status_url", check_status_url);
      setValue("phone_pe_callback_url", phone_pe_callback_url);
      setValue("phone_pe_redirect_url", phone_pe_redirect_url);
      setValue("phone_pe_salt_index", phone_pe_salt_index);
      setValue("dev_pay_url", dev_pay_url);
      setValue("dev_phone_pe_merchant_id", dev_phone_pe_merchant_id);
      setValue("dev_phone_pe_salt_key", dev_phone_pe_salt_key);
      setValue("dev_check_status_url", dev_check_status_url);
      setValue("dev_phone_pe_callback_url", dev_phone_pe_callback_url);
      setValue("dev_phone_pe_redirect_url", dev_phone_pe_redirect_url);
      setValue("dev_phone_pe_salt_index", dev_phone_pe_salt_index);
      setValue("is_developer_mode", is_developer_mode);
    }
  }, [appSettings]);

  // console.log("🚀 ~ file: AppSettingsPaymentGateways.js:52 ~ useEffect ~ atch:", watch("is_developer_mode"));
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="portlet">
            <div className="horizontal_filter_card">
              <div className="row mb-5">
                <div className="col-6 col-md-6">
                  <div className="payment_info_container d-flex justify-content-between">
                    <h3>Indian PG</h3>
                    {/* <div className="d-flex">
                      <h5 className="mr-2">Developer Mode:</h5>
                      <div className="form-check form-switch">
                        <input
                          {...register("is_developer_mode")}
                          className="form-check-input pointer"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          disabled={isView}
                          onChange={(e) => setValue("is_developer_mode", e.target.checked)}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-6 col-md-6"></div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="payment_info_container">
                    <div className="payment_blue_section">
                      <div className="payment_info-header mb-4">
                        <div className="payment_company_tag">
                          {/* <img src={publicUrl + "/assets/images/site_icons/payment_img.svg"} alt="image" /> */}
                          <span>PhonePe Payments Gateway</span>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            {...register("is_phone_pe_disable")}
                            className="form-check-input pointer"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            disabled={isView}
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">Phone Pe URL</label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input
                            {...register("pay_url", {
                              required: "URL is required.",
                            })}
                            aria-invalid={errors.pay_url ? "true" : "false"}
                            className="form-control form-control-lg bg-white"
                            type="text"
                            placeholder="Enter url"
                            disabled={isView}
                          />
                          {errors.pay_url && (
                            <p role="alert" className="text-danger">
                              {errors.pay_url?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">MID ID</label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input
                            {...register("phone_pe_merchant_id", {
                              required: "Merchant id is required.",
                            })}
                            aria-invalid={errors.phone_pe_merchant_id ? "true" : "false"}
                            className="form-control form-control-lg bg-white"
                            type="text"
                            placeholder="Enter merchant id"
                            disabled={isView}
                          />
                          {errors.phone_pe_merchant_id && (
                            <p role="alert" className="text-danger">
                              {errors.phone_pe_merchant_id?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">Check Status URL</label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input
                            {...register("check_status_url", {
                              required: "Status url is required.",
                            })}
                            aria-invalid={errors.check_status_url ? "true" : "false"}
                            className="form-control form-control-lg bg-white"
                            type="text"
                            placeholder="Enter status url"
                            disabled={isView}
                          />
                          {errors.check_status_url && (
                            <p role="alert" className="text-danger">
                              {errors.check_status_url?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">Redirect URL</label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input
                            {...register("phone_pe_redirect_url", {
                              required: "Redirect URL is required.",
                            })}
                            aria-invalid={errors.phone_pe_redirect_url ? "true" : "false"}
                            className="form-control form-control-lg bg-white"
                            type="text"
                            placeholder="Enter redirect url"
                            disabled={isView}
                          />
                          {errors.phone_pe_redirect_url && (
                            <p role="alert" className="text-danger">
                              {errors.phone_pe_redirect_url?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">Callback URL</label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input
                            {...register("phone_pe_callback_url", {
                              required: "Callback URL is required.",
                            })}
                            aria-invalid={errors.phone_pe_callback_url ? "true" : "false"}
                            className="form-control form-control-lg bg-white"
                            type="text"
                            placeholder="Enter callback url"
                            disabled={isView}
                          />
                          {errors.phone_pe_callback_url && (
                            <p role="alert" className="text-danger">
                              {errors.phone_pe_callback_url?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">PhonePe Salt Key</label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input
                            {...register("phone_pe_salt_key", {
                              required: "Salt key is required.",
                            })}
                            aria-invalid={errors.phone_pe_salt_key ? "true" : "false"}
                            className="form-control form-control-lg bg-white"
                            type="text"
                            placeholder="Enter salt key"
                            disabled={isView}
                          />
                          {errors.phone_pe_salt_key && (
                            <p role="alert" className="text-danger">
                              {errors.phone_pe_salt_key?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">PhonePe Salt Index</label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input
                            {...register("phone_pe_salt_index", {
                              required: "Salt index is required.",
                            })}
                            aria-invalid={errors.phone_pe_salt_index ? "true" : "false"}
                            className="form-control form-control-lg bg-white"
                            type="text"
                            placeholder="Enter salt index"
                            disabled={isView}
                          />
                          {errors.phone_pe_salt_index && (
                            <p role="alert" className="text-danger">
                              {errors.phone_pe_salt_index?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* {!watch("is_developer_mode") ? (
                        <>
                          <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">Phone Pe URL</label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <input
                                {...register("pay_url", {
                                  required: "URL is required.",
                                })}
                                aria-invalid={errors.pay_url ? "true" : "false"}
                                className="form-control form-control-lg bg-white"
                                type="text"
                                placeholder="Enter url"
                                disabled={isView}
                              />
                              {errors.pay_url && (
                                <p role="alert" className="text-danger">
                                  {errors.pay_url?.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">MID ID</label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <input
                                {...register("phone_pe_merchant_id", {
                                  required: "Merchant id is required.",
                                })}
                                aria-invalid={errors.phone_pe_merchant_id ? "true" : "false"}
                                className="form-control form-control-lg bg-white"
                                type="text"
                                placeholder="Enter merchant id"
                                disabled={isView}
                              />
                              {errors.phone_pe_merchant_id && (
                                <p role="alert" className="text-danger">
                                  {errors.phone_pe_merchant_id?.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">Check Status URL</label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <input
                                {...register("check_status_url", {
                                  required: "Status url is required.",
                                })}
                                aria-invalid={errors.check_status_url ? "true" : "false"}
                                className="form-control form-control-lg bg-white"
                                type="text"
                                placeholder="Enter status url"
                                disabled={isView}
                              />
                              {errors.check_status_url && (
                                <p role="alert" className="text-danger">
                                  {errors.check_status_url?.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">Redirect URL</label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <input
                                {...register("phone_pe_redirect_url", {
                                  required: "Redirect URL is required.",
                                })}
                                aria-invalid={errors.phone_pe_redirect_url ? "true" : "false"}
                                className="form-control form-control-lg bg-white"
                                type="text"
                                placeholder="Enter redirect url"
                                disabled={isView}
                              />
                              {errors.phone_pe_redirect_url && (
                                <p role="alert" className="text-danger">
                                  {errors.phone_pe_redirect_url?.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">Callback URL</label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <input
                                {...register("phone_pe_callback_url", {
                                  required: "Callback URL is required.",
                                })}
                                aria-invalid={errors.phone_pe_callback_url ? "true" : "false"}
                                className="form-control form-control-lg bg-white"
                                type="text"
                                placeholder="Enter callback url"
                                disabled={isView}
                              />
                              {errors.phone_pe_callback_url && (
                                <p role="alert" className="text-danger">
                                  {errors.phone_pe_callback_url?.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">PhonePe Salt Key</label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <input
                                {...register("phone_pe_salt_key", {
                                  required: "Salt key is required.",
                                })}
                                aria-invalid={errors.phone_pe_salt_key ? "true" : "false"}
                                className="form-control form-control-lg bg-white"
                                type="text"
                                placeholder="Enter salt key"
                                disabled={isView}
                              />
                              {errors.phone_pe_salt_key && (
                                <p role="alert" className="text-danger">
                                  {errors.phone_pe_salt_key?.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">PhonePe Salt Index</label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <input
                                {...register("phone_pe_salt_index", {
                                  required: "Salt index is required.",
                                })}
                                aria-invalid={errors.phone_pe_salt_index ? "true" : "false"}
                                className="form-control form-control-lg bg-white"
                                type="text"
                                placeholder="Enter salt index"
                                disabled={isView}
                              />
                              {errors.phone_pe_salt_index && (
                                <p role="alert" className="text-danger">
                                  {errors.phone_pe_salt_index?.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">Phone Pe URL</label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <input
                                {...register("dev_pay_url", {
                                  required: "URL is required.",
                                })}
                                aria-invalid={errors.dev_pay_url ? "true" : "false"}
                                className="form-control form-control-lg bg-white"
                                type="text"
                                placeholder="Enter url"
                                disabled={isView}
                              />
                              {errors.dev_pay_url && (
                                <p role="alert" className="text-danger">
                                  {errors.dev_pay_url?.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">MID ID</label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <input
                                {...register("dev_phone_pe_merchant_id", {
                                  required: "Merchant id is required.",
                                })}
                                aria-invalid={errors.dev_phone_pe_merchant_id ? "true" : "false"}
                                className="form-control form-control-lg bg-white"
                                type="text"
                                placeholder="Enter merchant id"
                                disabled={isView}
                              />
                              {errors.dev_phone_pe_merchant_id && (
                                <p role="alert" className="text-danger">
                                  {errors.dev_phone_pe_merchant_id?.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">Check Status URL</label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <input
                                {...register("dev_check_status_url", {
                                  required: "Status url is required.",
                                })}
                                aria-invalid={errors.dev_check_status_url ? "true" : "false"}
                                className="form-control form-control-lg bg-white"
                                type="text"
                                placeholder="Enter status url"
                                disabled={isView}
                              />
                              {errors.dev_check_status_url && (
                                <p role="alert" className="text-danger">
                                  {errors.dev_check_status_url?.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">Redirect URL</label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <input
                                {...register("dev_phone_pe_redirect_url", {
                                  required: "Redirect URL is required.",
                                })}
                                aria-invalid={errors.dev_phone_pe_redirect_url ? "true" : "false"}
                                className="form-control form-control-lg bg-white"
                                type="text"
                                placeholder="Enter redirect url"
                                disabled={isView}
                              />
                              {errors.dev_phone_pe_redirect_url && (
                                <p role="alert" className="text-danger">
                                  {errors.dev_phone_pe_redirect_url?.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">Callback URL</label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <input
                                {...register("dev_phone_pe_callback_url", {
                                  required: "Callback URL is required.",
                                })}
                                aria-invalid={errors.dev_phone_pe_callback_url ? "true" : "false"}
                                className="form-control form-control-lg bg-white"
                                type="text"
                                placeholder="Enter callback url"
                                disabled={isView}
                              />
                              {errors.dev_phone_pe_callback_url && (
                                <p role="alert" className="text-danger">
                                  {errors.dev_phone_pe_callback_url?.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">PhonePe Salt Key</label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <input
                                {...register("dev_phone_pe_salt_key", {
                                  required: "Salt key is required.",
                                })}
                                aria-invalid={errors.dev_phone_pe_salt_key ? "true" : "false"}
                                className="form-control form-control-lg bg-white"
                                type="text"
                                placeholder="Enter salt key"
                                disabled={isView}
                              />
                              {errors.dev_phone_pe_salt_key && (
                                <p role="alert" className="text-danger">
                                  {errors.dev_phone_pe_salt_key?.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">PhonePe Salt Index</label>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <input
                                {...register("dev_phone_pe_salt_index", {
                                  required: "Salt index is required.",
                                })}
                                aria-invalid={errors.dev_phone_pe_salt_index ? "true" : "false"}
                                className="form-control form-control-lg bg-white"
                                type="text"
                                placeholder="Enter salt index"
                                disabled={isView}
                              />
                              {errors.dev_phone_pe_salt_index && (
                                <p role="alert" className="text-danger">
                                  {errors.dev_phone_pe_salt_index?.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      )} */}
                    </div>
                  </div>
                </div>

                {/* <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="payment_info_container">
                    <div className="payment_blue_section">
                      <div className="payment_info-header mb-4">
                        <div className="payment_company_tag">
                          <img src={publicUrl + "/assets/images/site_icons/justpay_img.svg"} alt="image" />
                          <span>Paytm Payments Gateway</span>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            checked=""
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                          MID ID
                        </label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input
                            className="form-control form-control-lg bg-white"
                            type="text"
                            placeholder=""
                            value="WAR9434sdf4s65ds56d56sd4s56d456"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                          Merchant Key
                        </label>
                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input
                            className="form-control form-control-lg bg-white"
                            type="text"
                            placeholder=""
                            value="TAAK%dfuh&sdhsdj4"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              {/* <div className="row">
              <div className="col-12 col-md-12">
                <div className="payment_info_container">
                  <h3>Non Indian PG</h3>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="payment_info_container">
                  <div className="payment_blue_section">
                    <div className="payment_info-header mb-4">
                      <div className="payment_company_tag">
                        <img src={publicUrl + "/assets/images/site_icons/payment_img.svg"} alt="image" />
                        <span>Paytm Payments Gateway</span>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          checked=""
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                        MID ID
                      </label>
                      <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <input
                          className="form-control form-control-lg bg-white"
                          type="text"
                          placeholder=""
                          value="WAR9434sdf4s65ds56d56sd4s56d456"
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                        Merchant Key
                      </label>
                      <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <input
                          className="form-control form-control-lg bg-white"
                          type="text"
                          placeholder=""
                          value="TAAK%dfuh&sdhsdj4"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
              {!isView && (
                <div className="row justify-content-end mt-5">
                  <div className="col-12 col-md-12">
                    <div className="text-end">
                      <button className="btn btn-primary btn-lg common_hover ps-5 pe-5">Update</button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
