import React, { useEffect, useMemo, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as API from "../../../utils/api.service";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import { setLoader } from "../../../store/slices/masterSlice";

export default function AppSettingsBlockWords({ isView }) {
  const [page, setPage] = useState(1);
  const [badWordsList, setBadWordsList] = useState(null);
  const containerRef = useRef(null);
  // console.log("badWordsList: ", badWordsList);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    getBadWordsList();
  }, [page]);

  const getBadWordsList = async () => {
    try {
      dispatch(setLoader(true));
      const { message, code, data } = await API.getBadWordsList(page);
      if (code === 1) {
        if (page === 1) setBadWordsList(data);
        else
          setBadWordsList({
            ...badWordsList,
            results: [...badWordsList.results, ...data.results],
          });
      } else TOAST_ERROR(message);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  const onSubmit = async (data) => {
    try {
      dispatch(setLoader(true));
      data.bad_words_list = [data.bad_words_list];
      const { message, code } = await API.addBadWords(data);
      if (code === 1) {
        TOAST_SUCCESS(message);
        reset();
        getBadWordsList();
      } else TOAST_ERROR(message);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  const handleRemoveBadWord = async (id) => {
    try {
      dispatch(setLoader(true));
      const { message, code } = await API.deleteBadWords(id);
      if (code === 1) {
        TOAST_SUCCESS(message);
        getBadWordsList();
      } else TOAST_ERROR(message);
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(setLoader(false));
      TOAST_ERROR(err.message);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (
        container.scrollHeight - container.scrollTop ===
          container.clientHeight &&
        badWordsList?.results?.length < badWordsList?.count
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    };
    containerRef.current.addEventListener("scroll", handleScroll);
  }, [badWordsList]);

  const showBadWords = useMemo(() => {
    return badWordsList?.results?.map((item, i) => {
      return (
        <div
          key={item.id}
          className="d-flex justify-content-start my-2 p-2 mx-2 border rounded"
          style={{ height: "fit-content" }}
        >
          <p className="my-auto mr-2">{item.word}</p>
          {!isView && (
            <i
              onClick={() => handleRemoveBadWord(item.id)}
              className="fa fa-close text-danger pointer my-auto"
              aria-hidden="true"
            ></i>
          )}
        </div>
      );
    });
  }, [badWordsList]);

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="portlet">
          <div className="horizontal_filter_card">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-4">
                <label className="col-12 col-xl-12 col-sm-12 col-lg-12 col-md-12 col-form-label text-sm-start">
                  Blacklist Word
                </label>
                <div className="col-10 col-xl-10 col-lg-10 col-md-10 ">
                  <input
                    {...register("bad_words_list", {
                      required: "Word is required.",
                    })}
                    aria-invalid={errors.bad_words_list ? "true" : "false"}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder="Enter blacklist word"
                    disabled={isView}
                  />
                  {errors.bad_words_list && (
                    <p role="alert" className="text-danger">
                      {errors.bad_words_list?.message}
                    </p>
                  )}
                </div>
                {!isView && (
                  <div className="col-2 col-md-2 col-sm-2">
                    <button className="btn btn-primary btn-lg common_hover w-100">
                      Add
                    </button>
                  </div>
                )}
              </div>
            </form>
            <div
              ref={containerRef}
              className="w-100 list-box d-flex justify-content-start flex-wrap"
            >
              {showBadWords}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
