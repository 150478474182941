import request from "./axios.service";
import { GET, POST, PATCH, DELETE, BASE_URL, ADMIN, OPTION, COUNT_PER_PAGE } from "../app.config";

// <-----------------auth------------------>
export const organizerLogin = (body) => {
  return request(`${BASE_URL}/${ADMIN}/private_organizers/auth/login/`, POST, false, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const organizerLogout = () => {
  return request(`${BASE_URL}/${ADMIN}/private_organizers/auth/logout/`, POST, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const login = (body) => {
  return request(`${BASE_URL}/${ADMIN}/custom-auth/auth/login/`, POST, false, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const logout = () => {
  return request(`${BASE_URL}/${ADMIN}/custom-auth/auth/logout/`, POST, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const forgotPassword = (body) => {
  return request(`${BASE_URL}/${ADMIN}/custom-auth/auth/forgot-password/`, POST, false, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const otpVerification = (body) => {
  return request(`${BASE_URL}/${ADMIN}/custom-auth/auth/otp-verification/`, POST, false, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const resetPassword = (body) => {
  return request(`${BASE_URL}/${ADMIN}/custom-auth/auth/reset-password/`, POST, false, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCountryList = (startsWith) => {
  return request(`${BASE_URL}/country/${startsWith ? `?starts_with=${startsWith}` : ""}`, GET, false, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------games------------------>

export const getGameList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/games/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createGame = (body) => {
  return request(`${BASE_URL}/${ADMIN}/games/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getGameDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/games/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateGame = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/games/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteGame = (id) => {
  return request(`${BASE_URL}/${ADMIN}/games/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getMapListByGameId = (id) => {
  return request(`${BASE_URL}/${ADMIN}/games/${id}/game_maps/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getGameMapList = (id) => {
  return request(`${BASE_URL}/${ADMIN}/games/${id}/game_maps/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTournamentTypeByGameId = (id) => {
  return request(`${BASE_URL}/${ADMIN}/games/${id}/get_tournament_type/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTournamentTypeMatchTypeByGameId = (id) => {
  return request(`${BASE_URL}/${ADMIN}/games/${id}/get_tournament_type_match_type/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getGameIdFormatList = () => {
  return request(`${BASE_URL}/${ADMIN}/games/games_id_format/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getGamePlatformList = () => {
  return request(`${BASE_URL}/${ADMIN}/game_platform/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTournamentTypeList = () => {
  return request(`${BASE_URL}/${ADMIN}/games/game_type/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------events------------------>
export const getEventList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/events/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const eventDelete = (id) => {
  return request(`${BASE_URL}/${ADMIN}/events/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createEvent = (data) => {
  return request(`${BASE_URL}/${ADMIN}/events/`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const eventDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/events/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const eventUpdate = (id, data) => {
  return request(`${BASE_URL}/${ADMIN}/events/${id}/`, PATCH, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------advertisement------------------>
export const getAdvertisementList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/advertisements/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const advertisementDelete = (id) => {
  return request(`${BASE_URL}/${ADMIN}/advertisements/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createAdvertisement = (data) => {
  return request(`${BASE_URL}/${ADMIN}/advertisements/`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const advertisementDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/advertisements/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const advertisementUpdate = (id, data) => {
  return request(`${BASE_URL}/${ADMIN}/advertisements/${id}/`, PATCH, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------tournament------------------>

export const createTournament = (body) => {
  return request(`${BASE_URL}/${ADMIN}/tournament/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTournamentDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/tournament/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateTournament = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/tournament/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteTournament = (id) => {
  return request(`${BASE_URL}/${ADMIN}/tournament/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTournamentList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/tournament/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTournamentListByGameId = (id, page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/tournament/tournament_list/${id}/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getRoomListByTournamentId = (id, page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/tournament/room_list/${id}/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getRoomDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/tournament/room/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getJoinedMembersListByGroup = (id) => {
  return request(`${BASE_URL}/${ADMIN}/tournament/room_join/?room_id=${id}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getJoinedMembersList = (id) => {
  return request(`${BASE_URL}/${ADMIN}/tournament/room_winners/?room_id=${id}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateMemberKillCountByRoomId = (id, memberId, body) => {
  return request(`${BASE_URL}/${ADMIN}/tournament/${id}/update_kills/${memberId}`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateRoom = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/tournament/room/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteRoom = (id) => {
  return request(`${BASE_URL}/${ADMIN}/tournament/room/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateRoomMemberStatus = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/tournament/room_join/${id}/action_method/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const uploadRoomImages = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/tournament/room_image/${id}/set_photo/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getRoomImages = (id) => {
  return request(`${BASE_URL}/${ADMIN}/tournament/room_image/?id=${id}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------story------------------>
export const getStoryList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/story/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createStory = (body) => {
  return request(`${BASE_URL}/${ADMIN}/story/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getStoryDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/story/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateStory = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/story/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteStory = (id) => {
  return request(`${BASE_URL}/${ADMIN}/story/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateInactiveOrDeleteStory = (storyId, subStoryId, bodyData) => {
  return request(`${BASE_URL}/${ADMIN}/story/${storyId}/status/${subStoryId}`, PATCH, true, bodyData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------banners------------------>
export const getBannersList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/banners/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createBanner = (body) => {
  return request(`${BASE_URL}/${ADMIN}/banners/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAppRedirectionList = () => {
  return request(`${BASE_URL}/${ADMIN}/banners/in_app_redirection/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const selectItemsList = (data) => {
  return request(`${BASE_URL}/${ADMIN}/banners/select_items/`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateBanner = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/banners/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getBannerDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/banners/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteBanner = (id) => {
  return request(`${BASE_URL}/${ADMIN}/banners/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getActiveInactiveBannerList = (active) => {
  return request(`${BASE_URL}/${ADMIN}/banners/?is_active=${active}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------users------------------>

export const getUsersList = (page, filters) => {
  return request(
    `${BASE_URL}/${ADMIN}/user_profile/?is_superuser=false${page ? `&p=${page}&page_size=${COUNT_PER_PAGE}` : ""}${filters || ""}`,
    GET,
    true,
    {}
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUsersDetaile = (id) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const activeInactiveUser = (bodyData) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/active-inactive-history/`, POST, true, bodyData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getReferralUserList = (id, referralCode) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/?referred_by=${id}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserWalletList = (id) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/wallet/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateUserWalletList = (id, bodyData) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/wallet/${id}/`, PATCH, true, bodyData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateUserDetails = (id, bodyData) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/${id}/`, PATCH, true, bodyData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getBlockDeviceList = (userId) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/block_device${userId}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateBlockUnblockDevice = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/block_device/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const blockUnblockAllDevices = (bodyData) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/block_device/block-unblock-all-devices/`, POST, true, bodyData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getBlockIpList = (userId) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/block_ip_address${userId}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateBlockUnblockIp = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/block_ip_address/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const convertCashToWinning = (body) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/convert_cash_to_winning/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addFollow = (bodyData) => {
  return request(`${BASE_URL}/user/add_follow/`, PATCH, true, bodyData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const removeFollow = (userId) => {
  return request(`${BASE_URL}/user/${userId}/unfollow/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const blockUnblockAllIp = (bodyData) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/block_ip_address/block-unblock-all-ips/`, POST, true, bodyData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTournamentDetailsCount = (id) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/tournament-details/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserCardList = (id) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/card/?user__id=${id}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteUserCard = (id) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/card/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createUserCard = (bodyData) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/card/`, POST, true, bodyData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserCardDetailed = (id) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/card/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserReportList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/report/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserReportDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/report/${id}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSingleUserReport = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/report/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateAllUserReport = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/report/${id}/all_update/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserChatReportList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/reports/chat-user-report/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserChatReportDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/reports/chat-user-report/${id}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserPostList = (id, page) => {
  return request(`${BASE_URL}/${ADMIN}/posts/?user=${id}&p=${page}&page_size=${COUNT_PER_PAGE}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserFollowerList = (id, page) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/${id}/follower/?p=${page}&page_size=${COUNT_PER_PAGE}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserFollowingList = (id, page) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/${id}/following/?p=${page}&page_size=${COUNT_PER_PAGE}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserBlockedList = (id, page) => {
  return request(`${BASE_URL}/reports/chat-user-report/?p=${page}&page_size=${COUNT_PER_PAGE}&user=${id}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createUser = (body) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteUser = (id) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------reason------------------>

export const getReasonList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/reasons/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getReasonTypeList = () => {
  return request(`${BASE_URL}/${ADMIN}/reasons/`, OPTION, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getReasonDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/reasons/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createReason = (body) => {
  return request(`${BASE_URL}/${ADMIN}/reasons/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateReason = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/reasons/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteReason = (id) => {
  return request(`${BASE_URL}/${ADMIN}/reasons/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------post------------------>

export const getPostList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/posts/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPostDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/posts/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createPost = (body) => {
  return request(`${BASE_URL}/${ADMIN}/posts/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updatePost = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/posts/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deletePost = (id) => {
  return request(`${BASE_URL}/${ADMIN}/posts/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addComment = (body) => {
  return request(`${BASE_URL}/${ADMIN}/posts/comment/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const replyComment = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/posts/comment/${id}/reply/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteComment = (id) => {
  return request(`${BASE_URL}/${ADMIN}/posts/comment/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addPostLike = (postId) => {
  return request(`${BASE_URL}/post/${postId}/like/`, PATCH, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPostReportList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/posts/report/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPostReportDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/posts/report/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updatePostReport = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/posts/report/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------sub admin------------------>

export const getSubAdminList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/custom-auth/sub-admin/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSubAdminDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/custom-auth/sub-admin/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createSubAdmin = (body) => {
  return request(`${BASE_URL}/${ADMIN}/custom-auth/sub-admin/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSubAdmin = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/custom-auth/sub-admin/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteSubAdmin = (id) => {
  return request(`${BASE_URL}/custom-auth/sub-admin/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------groups------------------>

export const getGroupMenuList = () => {
  return request(`${BASE_URL}/${ADMIN}/permission_groups/menus/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getGroupPermissionList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/permission_groups/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getGroupPermissionDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/permission_groups/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createGroupPermission = (body) => {
  return request(`${BASE_URL}/${ADMIN}/permission_groups/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateGroupPermission = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/permission_groups/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteGroupPermission = (id) => {
  return request(`${BASE_URL}/permission_groups/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------spotlight------------------>

export const getSpotlightList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/spotlight/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSpotlightDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/spotlight/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSpotlight = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/spotlight/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSpotlightPriceList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/spotlight/spotlight_price/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSpotlightPriceDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/spotlight/spotlight_price/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createSpotlightPrice = (body) => {
  return request(`${BASE_URL}/${ADMIN}/spotlight/spotlight_price/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSpotlightPrice = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/spotlight/spotlight_price/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteSpotlight = (id) => {
  return request(`${BASE_URL}/${ADMIN}/spotlight/spotlight_price/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------products------------------>

export const getProductList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/product/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createProduct = (data) => {
  return request(`${BASE_URL}/${ADMIN}/product/`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const productDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/product/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateProduct = (id, data) => {
  return request(`${BASE_URL}/${ADMIN}/product/${id}/`, PATCH, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteProduct = (id) => {
  return request(`${BASE_URL}/${ADMIN}/product/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------category------------------>

export const getCategoryList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/category/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createCategory = (data) => {
  return request(`${BASE_URL}/${ADMIN}/category/`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCategoryDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/category/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateCategory = (id, data) => {
  return request(`${BASE_URL}/${ADMIN}/category/${id}/`, PATCH, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteCategory = (id) => {
  return request(`${BASE_URL}/${ADMIN}/category/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------orders------------------>

export const getOrderList = (page, filters) => {
  return request(`${BASE_URL}/orders/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateOrder = (id, data) => {
  return request(`${BASE_URL}/orders/${id}/`, PATCH, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------organizer------------------>

export const getOrganizerList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/private_organizers/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createOrganizer = (data) => {
  return request(`${BASE_URL}/${ADMIN}/private_organizers/`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getOrganizerDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/private_organizers/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateOrganizer = (id, data) => {
  return request(`${BASE_URL}/${ADMIN}/private_organizers/${id}/`, PATCH, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteOrganizer = (id) => {
  return request(`${BASE_URL}/${ADMIN}/private_organizers/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------pro verification------------------>

export const getVerificationBadgeList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/badge_request/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateVerificationBadge = (id, data) => {
  return request(`${BASE_URL}/${ADMIN}/badge_request/${id}/`, PATCH, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteVerificationBadge = (id) => {
  return request(`${BASE_URL}/${ADMIN}/badge_request/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------customer dispute------------------>

export const getCustomerDisputeList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/reports/dispute-report/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCustomerDisputeDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/reports/dispute-report/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------Custom Chat------------------>

export const getCustomChatList = (page, filters) => {
  return request(`${BASE_URL}/custom-chat/chat/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCustomChatHistory = (page, filters) => {
  return request(`${BASE_URL}/custom-chat/chat-message/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getChatGroupList = (page, filters) => {
  return request(`${BASE_URL}/custom-chat/chat/?p=${page}&page_size=${COUNT_PER_PAGE}&chat_type=group${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createChatGroup = (body) => {
  return request(`${BASE_URL}/custom-chat/chat/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateChatGroup = (id, body) => {
  return request(`${BASE_URL}/custom-chat/chat/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteChatGroup = (id) => {
  return request(`${BASE_URL}/custom-chat/chat/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getChatGroupDetails = (id) => {
  return request(`${BASE_URL}/custom-chat/chat/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------Support------------------>
export const addGroupMember = (id, body) => {
  return request(`${BASE_URL}/custom-chat/group-member/add-multiple-participants/${id}/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getGroupMembersList = (id, page) => {
  return request(`${BASE_URL}/custom-chat/group-member/?p=${page}&page_size=${COUNT_PER_PAGE}&is_active=1&chat__conversation_id=${id}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSupportList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/chatbot/user-support-bot-list/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const acceptSupportRequest = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/chatbot/support-bot-request/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAdminAcceptedSupportList = (page, filters) => {
  return request(
    `${BASE_URL}/${ADMIN}/chatbot/user-support-chat-list/?p=${page}&page_size=${COUNT_PER_PAGE}&chat_type=support_bot${filters || ""}`,
    GET,
    true,
    {}
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTicketSupportList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/chatbot/admin-support-list/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateTicket = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/chatbot/admin-support-list/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------KYC------------------>
export const getUserKycList = (page, filters) => {
  return request(`${BASE_URL}/kyc/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteUserKyc = (id) => {
  return request(`${BASE_URL}/kyc/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------Settings------------------>

export const getGoldSubscriptionsList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/membership/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getGoldSubscriptionPlanDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/membership/${id}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addGoldSubscriptionPlan = (body) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/membership/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateGoldSubscriptionPlan = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/membership/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteGoldSubscriptionPlan = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/membership/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getMembershipNameList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/membership-name/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getMembershipFeatureList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/membership-features/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getMembershipFeatureDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/membership-features/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addMembershipFeature = (body) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/membership-features/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const editMembershipFeature = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/membership-features/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteMembershipFeature = (id) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/membership-features/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getGoldMembersList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAppSettingsDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateAppSettings = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addAppSettings = (body) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAppSettingsList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteAppSettings = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/${id}/`, DELETE, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// ********************************           Country Section Start          ************************************************************//

export const addCountry = (body) => {
  return request(`${BASE_URL}/${ADMIN}/country/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCountryDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/country/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateCountryDetails = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/country/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteCountry = (id) => {
  return request(`${BASE_URL}/${ADMIN}/country/${id}`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCountryFilter = (startsWith) => {
  return request(`${BASE_URL}/${ADMIN}/country/${startsWith !== undefined && startsWith !== '' ? `?${startsWith}` : ''}`, GET, true, {})
    .then((response) => { 
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCountryPagination = (page, startsWith) => {
  return request(`${BASE_URL}/${ADMIN}/country/?p=${page}&page_size=${COUNT_PER_PAGE}${startsWith !== undefined && startsWith !== '' ? `&${startsWith}` : ''}`, GET, true, {})
    .then((response) => { 
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};


// ********************************     Country Section End      ************************************************************//

export const getEmailPurposeList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/email_purpose/?p=${page}&page_size=${COUNT_PER_PAGE}&is_active=1${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addEmailPurpose = (body) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/email_purpose/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteEmailPurpose = (id) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/email_purpose/${id}`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getBadWordsList = (page) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/badwords?p=${page}&page_size=50&is_active=1`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addBadWords = (body) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/badwords/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteBadWords = (id) => {
  return request(`${BASE_URL}/${ADMIN}/app_setting/badwords/${id}`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------Payment History------------------>

export const getPaymentHistory = (page, filters) => {
  return request(`${BASE_URL}/payment/payment-history-list/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPaymentHistoryDetails = (id) => {
  return request(`${BASE_URL}/payment/payment-history-list/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------Add Money------------------>
export const addMoney = (body) => {
  return request(`${BASE_URL}/payment/phone_pe/admin_pay_api/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// export const paymentResponse = (id) => {
//   return request(`https://mercury-uat.phonepe.com/transact/emulatedApis/v1/transaction/status?t=${id}`, GET, true, {})
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       throw error;
//     });
// };

export const checkStatus = (merchant_id, user_id) => {
  return request(`${BASE_URL}/payment/phone_pe/check_status/${merchant_id}/${user_id}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addWalletMoney = (body) => {
  return request(`${BASE_URL}/withdraw/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------Dashboard------------------>

export const getWar94DashboardDetails = (filters) => {
  return request(`${BASE_URL}/${ADMIN}/dashboard/${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPrivateDashboardDetails = (filters) => {
  return request(`${BASE_URL}/${ADMIN}/dashboard/private/${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserTournamentList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/tournaments_admin/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getGameCharacterDetails = (filters) => {
  return request(`${BASE_URL}/${ADMIN}/user_profile/game_character_details/${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserActivityList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/recent_activity/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserChatViewAccess = (body) => {
  return request(`${BASE_URL}/custom-chat/view-access/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------Email Marketing------------------>

export const getEmailMarketingList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/email_marketing/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getEmailDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/email_marketing/${id}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createEmail = (body) => {
  return request(`${BASE_URL}/${ADMIN}/email_marketing/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateEmail = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/email_marketing/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteEmail = (id) => {
  return request(`${BASE_URL}/${ADMIN}/email_marketing/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------Push Notification------------------>

export const getPushNotificationList = (page, filters) => {
  return request(`${BASE_URL}/${ADMIN}/notifications/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPushNotificationDetails = (id) => {
  return request(`${BASE_URL}/${ADMIN}/notifications/${id}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createPushNotification = (body) => {
  return request(`${BASE_URL}/${ADMIN}/notifications/`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updatePushNotification = (id, body) => {
  return request(`${BASE_URL}/${ADMIN}/notifications/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deletePushNotification = (id) => {
  return request(`${BASE_URL}/${ADMIN}/notifications/${id}/`, DELETE, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------Business Report------------------>
export const getBusinessReport = (filters) => {
  return request(`${BASE_URL}/${ADMIN}/dashboard/business-report/${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getBusinessReportList = (page, filters, query) => {
  return request(`${BASE_URL}/${ADMIN}/dashboard/business-report/${query}/?p=${page}&page_size=${COUNT_PER_PAGE}${filters || ""}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getContactUsList = (page) => {
  return request(`${BASE_URL}/contact_us/?page=${page}`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const editContactUs = (id, body) => {
  return request(`${BASE_URL}/contact_us/${id}/`, PATCH, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getContactUsDetails = (id) => {
  return request(`${BASE_URL}/contact_us/${id}/`, GET, true, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
